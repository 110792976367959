import styled from 'styled-components';
import { queries } from "../../../../breakpoints";

export const MainContainer = styled.div`
    background-color: #F8F8F8;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
    height: auto;
    min-height: 800px;
    background-image: url("https://res.cloudinary.com/grubhub-marketing/image/upload/v1664301412/HERMES/2022/B2B/GHCORP/B2B-Corp-20220715-Pay-Card-Assets/Header_Desktop_2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 200px;
    @media (${queries.max_break_md}){
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: url("https://res.cloudinary.com/grubhub-marketing/image/upload/v1661977369/HERMES/2022/B2B/GHCORP/B2B-Corp-20220715-Pay-Card-Assets/Header_Mobile.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 25%;
        min-height: 670px;
        padding-bottom: 60px;
        justify-content: flex-end;
    }
    @media (${queries.max_break_sm}){
        background-image: url("https://res.cloudinary.com/grubhub-marketing/image/upload/v1661977369/HERMES/2022/B2B/GHCORP/B2B-Corp-20220715-Pay-Card-Assets/Header_Mobile.jpg");
        background-repeat: no-repeat;
        background-size: cover;
    }
`;

export const TextComponent = styled.div`
    max-width: 50%;
    padding-left: 12.5%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    text-align: left;
    flex-direction: column;
    @media (${queries.max_break_md}){
        padding: 55px 0px 30px 0px;
        width: 100%;
        max-width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    @media (${queries.max_break_sm}){
        width: 100%;
        max-width: 100%;
        height: auto;
        padding: 55px 0px 30px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
`;

export const Title = styled.h1`
    text-align: left;
    font-size: 46px;
    line-height: 1.2;
    font-family: "Scandia Bold", sans-serif;
    font-weight: normal;
    margin-bottom: 15px;
    color: #FFFFFF;
    letter-space: 0px;
    padding-right: 40%;
    @media (${queries.max_break_md}){
        font-family: "Scandia Bold", sans-serif;
        font-weight: normal;
        font-size: 32px;
        line-height: 1.2;
        color: #FFF;
        padding-right: 0;
        margin: 0px 20px 20px;
        text-align: center;
    }
`;

export const Subtitle = styled.h4`
    text-align: left;
    font-size: 18px;
    font-family: "Scandia Medium", sans-serif;
    font-weight: normal;
    margin-bottom: 20px;
    color: #FFFFFF;
    letter-space: 0px;
    line-height: 26px;
    padding-right: 35%;
    @media (${queries.max_break_md}){
        font-family: "Scandia Medium", sans-serif;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: #FFFFFF;
        text-align: center;
        margin: 0px 40px 30px 40px;
        padding: 0px;
    }
    @media (${queries.max_break_sm}){
        font-family: "Scandia Medium", sans-serif;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: #FFFFFF;
        text-align: center;
        margin: 0px 40px 30px 40px;
        padding: 0px;
    }
`;

export const ImageComponent = styled.div`
    min-width: 50%;
    height: 37.5vw;
    background-color: #F8F8F8;
    overflow: visible;
    @media (${queries.max_break_md}){
        height: 70.5vw;
        width: 100%;
        margin: 0px;
    }
    @media (${queries.max_break_sm}){
        width: 100%;
        height: 50vh;
        margin: 0px;
        overflow: hidden;
    }
`;