import styled from 'styled-components';
import { queries } from "../../../../breakpoints";

export const HIWTitle = styled.h3`
    font-family: "Scandia Bold", sans-serif;
    font-weight: bold;
    font-size: 32px;
    line-height: 1.2;
    width: 100%;
    text-align: center;
    margin-top: 100px;
    color: #000;
    @media (${queries.max_break_lg}){
        margin-top: 60px;
    }
`;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: ${({direction}) => direction};
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 30px 0%;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    @media (${queries.max_break_lg_big}){
        padding: 30px 40px;
    }
    @media (${queries.max_break_lg}){
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 20px 40px;
    }
`;

export const HIWTextSection = styled.div`
    width: 340px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

`;

export const HIWNumberContainer = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 20px 0px;
`;

export const HIWNumber = styled.div`
    border: solid 3px #4E38EE;
    border-radius: 100%;
    color: #4E38EE;
    font-family: "Scandia Regular", sans-serif;
    font-weight: bold;
    font-size: 28px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const HIWText = styled.p`
    width: 340px;
    font-family: "Scandia Medium", sans-serif;
    font-size: 24px;
    line-height: 1.33;
`;

export const ImageComponentContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: ${({ cj }) => cj};
        @media (${queries.max_break_lg}){
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }
`;

export const ImageComponent = styled.div`
    width: 340px;
    background-image: url("${({ src }) => src}");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 340px;
    border-radius: 20px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
`;

export const TextInImageComponent = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 17px;
    background-color: #FFFFFF;
    border-radius: 20px;
    height: 80px;
`;

export const TIIIComponent = styled.div`
    background-image: url("${({ src }) => src}");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 22px;
    width: 22px;
    margin: 10px;
`;

export const TIITComponent = styled.div`
    font-family: "Scandia Bold", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
`;
