import { MainContainer, Title, MarketoFooterWrapper, MarketoFormThankYou, Subtitle } from "./FooterForm.styles"
import MarketoForm from "../../../global/MarketoForm";

import React from 'react';

export const FooterForm = (props) => {
  return(
    <MainContainer>
      <Title>Contact us today to learn more</Title>
      <Subtitle id="payFooterThankYou" style={{ display: 'none' }}>Thanks for reaching out, we'll be in touch shortly!</Subtitle>
      <MarketoFooterWrapper>
        <MarketoForm
          id={1830}
          labelBellow={true}
          confirmMessage="Thanks for reaching out, we'll be in touch shortly!" 
          eventCategory="pay card footer interaction"
          eventAction="submit pay card footer form_cta"
        />
      </MarketoFooterWrapper>
    </MainContainer>
  )
};
