import Layout from "../components/global/Layout"
import React from "react"
import styled from 'styled-components';
import { queries } from "../breakpoints";
import { Header } from "../components/page-specific/Pay-Card/Header/Header";
import MarketoForm from "../components/global/MarketoForm";
import { Benefits } from "../components/page-specific/Pay-Card/Benefits/Benefits";
import { HIWComponent } from "../components/page-specific/Pay-Card/How-It-Works/HowItWorks";
import { FooterForm } from "../components/page-specific/Pay-Card/FooterForm/FooterForm";
import Button from "../components/global/Button"

const PayCard = (props) => {

    const benefitsSections = [
        {
            title: "Your employees enjoy:",
            benefits: [
                {
                    icon: "https://gh-marketing-wp-media.s3.amazonaws.com/corporate-grubhub/wp-content/uploads/2022/08/24093716/map.svg",
                    title: "Flexibility and reach",
                    text: "Employees can buy food from anywhere in the country, from their favorite restaurant to a local grocery store",
                },
                {
                    icon: "https://gh-marketing-wp-media.s3.amazonaws.com/corporate-grubhub/wp-content/uploads/2022/08/24093701/Hand_heart.svg",
                    title: "Equal benefits for all",
                    text: "Feed your team anywhere in the United States, regardless of if they are a remote, hybrid or in-person employee",
                },
            ],
            image: "https://gh-marketing-wp-media.s3.amazonaws.com/corporate-grubhub/wp-content/uploads/2022/08/24093719/paycard_reader.png",
        },
        {
            title: "Your company gets:",
            benefits: [
                {
                    icon: "https://gh-marketing-wp-media.s3.amazonaws.com/corporate-grubhub/wp-content/uploads/2022/08/24093657/cog.svg",
                    title: "Employer control",
                    text: "A food spend solution that, unlike a credit card or gift card, only requires payment for funds that are used and gives employers the control to set budget limits and purchase categories",
                },
                {
                    icon: "https://gh-marketing-wp-media.s3.amazonaws.com/corporate-grubhub/wp-content/uploads/2022/08/24093715/laptop.svg",
                    title: "Easy management",
                    text: "With consolidated invoicing, simply manage all food spend from one central account",
                },
            ],
            image: "https://gh-marketing-wp-media.s3.amazonaws.com/corporate-grubhub/wp-content/uploads/2022/08/24093706/laptop_meal.png",
        },
    ];

    const hiwSection = [
        {
            text: "Our dedicated Corporate Accounts team works with you to build a tailored meal perk program",
            image: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1663166098/HERMES/2022/B2B/GHCORP/B2B-Corp-20220715-Pay-Card-Assets/HowItWorks_1.png",
            textInImage: ""
        },
        {
            text: "Set your own rules, including ordering dates, budget and purchase restrictions",
            image: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1663166098/HERMES/2022/B2B/GHCORP/B2B-Corp-20220715-Pay-Card-Assets/HowItWorks_2.png",
            textInImage: ""
         }, 
        {
            text: "Start offering employees perks on an open restaurant network via our physical pay card and Grubhub meal credits",
            image: "https://res.cloudinary.com/grubhub-marketing/image/upload/v1663166098/HERMES/2022/B2B/GHCORP/B2B-Corp-20220715-Pay-Card-Assets/HowItWorks_3.png",
        },
    ];

    const showThankYouScreen = () => {
        // Show the thank you message  
        let thankYou = document.getElementById('marketo-form-header-thank-you')
        thankYou.style.display = 'block';

        // Hide the form
        let form = document.getElementById('marketo-form-header')
        form.style.display = "none"
    }

    return (
        <Layout
            location={props.location}
            title={"Grubhub Pay Card"}
            description={"Grubhub Pay Card Coming Soon - The new way to extend your meal perks program to everywhere food is sold."}
            utag_data={{
                brand: "grubhub"
            }}
        >
            <StyledMainContainer>
                <Header />
                <MarketoWrapper id="marketo-form-header">
                    <Title>Contact us today to learn more</Title>
                    <MarketoForm
                        id={1830}
                        labelBellow={true}
                        onSubmit={() => showThankYouScreen()}
                        eventCategory="pay card header interaction"
                        eventAction="submit pay card header form_cta"
                    />
                </MarketoWrapper>
                <MarketoFormThankYou id="marketo-form-header-thank-you" style={{ display: 'none' }}>
                    <img 
                    width={125}
                    alt=""
                    src="https://res.cloudinary.com/grubhub-marketing/image/upload/v1627673958/Grubhub%20Corporate%20Website/2021/Navigation/SuccessState-Checkmark.svg"/>
                    <h2>Thanks for reaching out, we'll be in touch shortly!</h2>
                    <p>In the meantime, check out our blog to learn more about supporting your hybrid workforce.</p>
                    <Button to="/blog/" typee="primary">See blog</Button>
                </MarketoFormThankYou>
                <Benefits benefitsSections={benefitsSections} />
                <HIWComponent HIWSection={hiwSection} />
                <FooterForm />
            </StyledMainContainer>
        </Layout>
    )
}

export default PayCard;

const StyledMainContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`;

const MarketoFormThankYou = styled.div`
    padding: 40px;
    text-align: center;
    img {
        max-width: 140px;
        margin-bottom: 10px;
    }
`;

const Title = styled.div`
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    max-width: 1110px;
    font-size: 24px;
    width: auto;
    line-height: 1.2;
    padding-bottom: 20px;
    color: #000000;
    font-family: "Scandia Bold", sans-serif;
    font-weight: normal;
    padding-top: 40px;
    background: #FFF;
    margin-top: -10vw;
    margin-bottom: -1px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 20px;
    -moz-border-radius-topleft: 20px;
    -moz-border-radius-topright: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-bottom: 180px;
    margin-bottom: -160px;
    @media (max-width: 1920px) {
        margin-top: -11vw;
    }
    @media (max-width: 1700px) {
        margin-top: -13vw;
    }
    @media (max-width: 1300px) {
        margin-top: -15vw;
    }
    @media (max-width: 1200px) {
        margin-left: 40px;
        margin-right: 40px;
    }
    @media (max-width: 1180px) {
        margin-top: -17vw;
    }
    @media (max-width: 1024px) {
        margin-top: -80px;
    }
    @media (${queries.max_break_lg}){
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 20px;
        margin-right: 20px;
        font-size: 20px;
    }
`;

const MarketoWrapper = styled.div`
    background: #F9F9F9;
    width: 100%;
    form {
        background: #FFF;
        box-shadow: 0 10px 10px 0px rgb(0 0 0 / 10%);
        max-width: 1110px;
        min-height: 200px;
        width: auto;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        padding: 20px 123px 50px;
        -webkit-border-bottom-left-radius: 20px;
        -webkit-border-bottom-right-radius: 20px;
        -moz-border-radius-bottomleft: 20px;
        -moz-border-radius-bottomright: 20px;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        margin-bottom: 20px;
        @media (max-width: 1200px) {
            margin-left: 40px;
            margin-right: 40px;
        }
        @media (${queries.max_break_lg}){
            padding-left: 20px;
            padding-right: 20px;
            margin-left: 20px;
            margin-right: 20px;
        }
        .mktoFormRow {
            display: flex;
            @media (${queries.max_break_md}){
                flex-direction: column;
            }
            > div {
                width: 100%;
                margin: 0 3px;
            }
            .mktoClear {
                display: none;
            }
            .mktoError {
                position: relative;
                left: 1.5rem;
                right: 0;
                bottom: 0;
                top: calc(100% + 3px);
                color: red;
                font-size: 12px;
                font-family: "Scandia Medium", sans-serif;
            }
            .mktoFieldWrap {
                position: relative;
                width: 100%;
                height: 48px;
                margin-bottom: 24px;
                &.valueInside {
                    input {
                        // padding: 0.75rem 1.25rem;
                    }
                    label {
                        top: 3px;
                        left: 1rem;
                        z-index: 5;
                        font-size: 12px;
                        color: var(--color-primary);
                        font-family: "Scandia Medium", sans-serif;
                        transition: all 0.2s ease-in-out;
                    }
                }
                input {
                    border: 1px solid;
                    position: absolute;
                    font-family: "Scandia Regular", sans-serif;
                    font-weight: 600;
                    color: #000;
                    font-size: 1rem;
                    line-height: inherit;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    resize: none;
                    width: 100%;
                    height: auto;
                    padding: 1rem 1.4rem 0.5rem;
                    background: transparent;
                    border-radius: 24px;
                    border: 2px solid #C7B8FF;
                    transition: all 0.2s ease-in-out;
                    &::placeholder {
                        opacity: 0;
                        visibility: hidden;
                        color: transparent;
                    }
                    &:focus {
                        outline: none;
                        border-color: var(--color-primary);
                    }
                    &:focus ~ .mktoLabel {
                        top: 3px;
                        left: 1rem;
                        z-index: 5;
                        font-size: 12px;
                        color: var(--color-primary);
                        font-family: "Scandia Medium", sans-serif;
                        transition: all 0.2s ease-in-out;
                    }
                }
                .mktoLabel {
                    position: absolute;
                    font-family: "Scandia Medium", sans-serif;
                    font-size: 16px;
                    line-height: inherit;
                    left: 1rem;
                    top: 0.75rem;
                    padding: 0 0.5rem;
                    transition: all 0.3s ease;
                    color: #6B6B83;
                }
            }
            
        }
    }
    .mktoButton {
        display: inline!important;
        padding: 12px 30px!important;
        border-radius: 100px!important;
        font-weight: bold!important;
        font-size: var(--fontSize-1)!important;
        min-width: 175px!important;
        display: inline-block!important;
        text-align: center!important;
        background: var(--color-primary)!important;
        color: white!important;
        font-family: var(--font-body)!important;
        border: none!important;
        transition: all .1s!important;
        width: 100%!important;
        &:hover {
            color: white!important;
            text-decoration: none!important;
            background: var(--color-primary-dark)!important;
        }
        &:active{
            background: var(--color-primary-darker)!important;
        }
    }
    .mktoAsterix{
        display:none!important;
    }

    .mktoRadioList {
        padding-top: 40px;
        padding-bottom: 20px;
        padding-left: 5px;
        max-width: 200px;
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .mktoRadioList input[type="radio"] {
        top: inherit !important;
        left: inherit !important;
        width: inherit !important;
        position: inherit !important;
        float: right;
        clear: right;
        width: 20px !important;
        height: 20px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
    .mktoRadioList label {
        display: block !important;
        float: left;
        clear: left;
        margin: 0 !important;
        padding: 0 !important;
        margin-right: 20px!important;
        padding-left: 4px!important;
    }
    #Lblheadcountbelow100 {
        top: 0 !important;
        left: 0 !important;
    }
    .mktoButtonRow {
        margin-top: 20px;
    }
`;
