import { BenefitsMainContainer, MainContainer, TextComponent, Title, BenefitContainer, BenefitIcon, BenefitTitle, BenefitText, ImageComponent, ImageComponentContainer, BenefitsSeparator } from "./Benefits.styles"

import React from 'react';

export const Benefits = ({benefitsSections}) => {

  return(
    <BenefitsMainContainer>
      {benefitsSections.map((benefitsSection, i) => <>
        <MainContainer
          direction={i % 2 === 0 ? "row" : "row-reverse"}
          key={i + Math.random()}
        >
          <TextComponent
            ai={i % 2 === 0 ? "flex-start" : "flex-end"}
          >
            <Title>{benefitsSection.title}</Title>
            {benefitsSection.benefits.map((benefit, i) => <BenefitContainer
              key={i + Math.random()}
            >
              <BenefitIcon
                src={benefit.icon}
              />
              <BenefitTitle>{benefit.title}</BenefitTitle>
              <BenefitText>{benefit.text}</BenefitText>
            </BenefitContainer>)}
          </TextComponent>
          <ImageComponentContainer
            cj={i % 2 === 0 ? "flex-end" : "flex-start"}
          >
            <ImageComponent
              src={benefitsSection.image}
            />
          </ImageComponentContainer>
        </MainContainer>
        {i < benefitsSections.length - 1 && <BenefitsSeparator />}
      </>)}
    </BenefitsMainContainer>
  );
};
