import { HIWTitle, MainContainer, HIWTextSection, HIWNumberContainer, HIWNumber, HIWText, ImageComponentContainer, ImageComponent, TextInImageComponent, TIIIComponent, TIITComponent } from "./HowItWorks.styles"

import React from 'react';

export const HIWComponent = ({HIWSection}) => {

  return(
    <>
      <HIWTitle>{"How it Works"}</HIWTitle>
      {HIWSection.map((HIWItem, i) => <MainContainer
        direction={i % 2 === 0 ? "row" : "row-reverse"}
      >
        <HIWTextSection>
          <HIWNumberContainer>
            <HIWNumber>{i + 1}</HIWNumber>
          </HIWNumberContainer>
          <HIWText>{HIWItem.text}</HIWText>
        </HIWTextSection>
        <ImageComponentContainer
          cj={i % 2 === 0 ? "flex-end" : "flex-start"}
        >
          <ImageComponent
            src={HIWItem.image}
          >
            {HIWItem.textInImage && <TextInImageComponent>
              <TIIIComponent
                src={"https://gh-marketing-wp-media.s3.amazonaws.com/corporate-grubhub/wp-content/uploads/2022/08/24093649/check_icon.svg"}
              />
              <TIITComponent>{HIWItem.textInImage}</TIITComponent>
            </TextInImageComponent>}
          </ImageComponent>
        </ImageComponentContainer>
      </MainContainer>)}
    </>
  );
};
