import styled from 'styled-components';
import { queries } from "../../../../breakpoints";

export const BenefitsMainContainer = styled.div`
    width: 100%;
    background-color: #F9F9F9;
`;

export const MainContainer = styled.div`
    display: flex;
    flex-direction: ${({direction}) => direction};
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #F9F9F9;
    padding: 100px 0%;
    max-width: 1140px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    @media (${queries.max_break_lg_big}){
        padding: 100px 40px;
    }
    @media (${queries.max_break_lg}){
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 60px 40px;
    }
`;

export const TextComponent = styled.div`
    min-width: 50%;
    display: flex;
    flex-direction: column;
    align-items: ${({ ai }) => ai};
    justify-content: center;
    @media (${queries.max_break_lg}){
        align-items: center;
        justify-content: center;
    }
`;

export const Title = styled.h2`
    font-size: 32px;
    line-height: 38.4px;
    color: #000000;
    font-family: "Scandia Bold", sans-serif;
    font-weight: normal;
    width: 100%;
    width: 420px;
    @media (${queries.max_break_lg}){
        text-align: center;
    }
    @media (${queries.max_break_sm}){
        width: 300px;
    }
`;

export const BenefitContainer = styled.div`
    display-flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
    width: 420px;
    @media (${queries.max_break_lg}){
        text-align: center;
    }
    @media (${queries.max_break_sm}){
        width: 300px;
    }
`;

export const BenefitIcon = styled.div`
    height: 80px;
    width: 80px;
    background-image: url("${({ src }) => src}");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    @media (${queries.max_break_lg}){
        margin-left: auto;
        margin-right: auto;
    }
`;

export const BenefitTitle = styled.h6`
    font-size: 18px;
    line-height: 24px;
    color #000000;
    font-family: "Scandia Bold", sans-serif;
    font-weight: normal;
`;

export const BenefitText = styled.p`
    font-family: "Scandia Regular", sans-serif;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
`;

export const ImageComponentContainer = styled.div`
    min-width: 50%;
    display: flex;
    align-items: center;
    justify-content: ${({cj}) => cj};
    @media (${queries.max_break_lg}){
        align-items: center;
        justify-content: center;
        margin-top: 30px;
    }
`;

export const ImageComponent = styled.div`
    width: 450px;
    background-image: url("${({ src }) => src}");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    height: 526px;
    border-radius: 20px;
    @media (min-width: 1200px) {
        width: 525px;
        height: 614px;
    }
    @media (${queries.max_break_sm}){
        width: 300px;
    }
`;

export const BenefitsSeparator = styled.div`
    margin: 0px 15%;
    height: 1px;
    width: 70%;
    background-color: #D4D4DB;
`;
