import styled from 'styled-components';
import { queries } from "../../../../breakpoints";

export const MarketoFormThankYou = styled.div`
`;

export const MainContainer = styled.div`
    background-color: #23232E;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 100px 0;
    width: 100%;
    margin-top: 80px;
    @media (${queries.max_break_lg}){
        margin-top: 60px;
        padding: 40px 0 30px;
    }
    @media (${queries.max_break_md}){
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    @media (${queries.max_break_sm}){
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
`;

export const TextComponent = styled.div`
    max-width: 50%;
    padding-left: 12.5%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    text-align: left;
    flex-direction: column;
    @media (${queries.max_break_md}){
        padding: 55px 0px 30px 0px;
        width: 100%;
        max-width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
    @media (${queries.max_break_sm}){
        width: 100%;
        max-width: 100%;
        height: auto;
        padding: 55px 0px 30px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
`;

export const Title = styled.h2`
    text-align: center;
    font-size: 24px;
    line-height: 1.2;
    font-family: "Scandia Bold", sans-serif;
    font-weight: normal;
    margin-bottom: 0px;
    padding-bottom: 20px;
    color: #FFF;
    @media (${queries.max_break_md}){
        font-family: "Scandia Bold", sans-serif;
        font-weight: normal;
        font-size: 20px;
        text-align: center;
        margin: 0px 40px;
        text-align: center;
        padding-bottom: 20px;
    }
`;

export const Subtitle = styled.h4`
    text-align: center;
    font-size: 24px;
    font-family: "Scandia Regular", sans-serif;
    font-weight: normal;
    color: #FFF;
    margin-top: 10px;
    line-height: 30px;
    @media (${queries.max_break_md}){
        font-family: "Scandia Regular", sans-serif;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: #FFF;
        text-align: center;
        margin: 0px 40px 30px 40px;
        padding: 0px;
    }
    @media (${queries.max_break_sm}){
        font-family: "Scandia Regular", sans-serif;
        font-weight: normal;
        font-size: 18px;
        line-height: 28px;
        color: #FFF;
        text-align: center;
        margin: 0px 40px 30px 40px;
        padding: 0px;
    }
`;

export const ImageComponent = styled.div`
    min-width: 50%;
    height: 37.5vw;
    background-color: #F8F8F8;
    background-image: url("https://corporate-wp-stage.grubhub.com/wp-content/uploads/2022/01/corp-hero-img-dsk.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center right;
    overflow: visible;
    @media (${queries.max_break_md}){
        height: 70.5vw;
        width: 100%;
        margin: 0px;
        background-image: url("https://corporate-wp-stage.grubhub.com/wp-content/uploads/2022/01/corp-hero-img-mob.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: bottom center;
    }
    @media (${queries.max_break_sm}){
        width: 100%;
        height: 50vh;
        margin: 0px;
        overflow: hidden;
        background-image: url("https://corporate-wp-stage.grubhub.com/wp-content/uploads/2022/01/corp-hero-img-mob.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom center;
    }
`;

export const MarketoFooterWrapper = styled.div`
    width: 100%;
    p {
        color: #FFF;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
    }
    form {
        max-width: 864px;
        min-height: 200px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        flex-direction: column;
        padding: 20px 40px 30px;
        width: auto;
        @media (${queries.max_break_lg}){
            padding-left: 20px;
            padding-right: 20px;
            margin-left: 20px;
            margin-right: 20px;
        }
        .mktoFormRow {
            display: flex;
            @media (${queries.max_break_md}){
                flex-direction: column;
            }
            > div {
                width: 100%;
                margin: 0 3px;
            }
            .mktoClear {
                display: none;
            }
            .mktoError {
                position: relative;
                left: 1.5rem;
                right: 0;
                bottom: 0;
                top: calc(100% + 3px);
                color: red;
                font-size: 12px;
                font-family: "Scandia Medium", sans-serif;
            }
            .mktoFieldWrap {
                position: relative;
                width: 100%;
                height: 48px;
                margin-bottom: 24px;
                &.valueInside {
                    input {
                        // padding: 0.75rem 1.25rem;
                    }
                    label {
                        top: 3px;
                        left: 1rem;
                        z-index: 5;
                        font-size: 12px;
                        color: #6B6B83;
                        font-family: "Scandia Medium", sans-serif;
                        transition: all 0.2s ease-in-out;
                    }
                }
                input {
                    border: 1px solid;
                    position: absolute;
                    font-family: "Scandia Regular", sans-serif;
                    font-weight: 600;
                    color: #FFF;
                    font-size: 1rem;
                    line-height: inherit;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    resize: none;
                    width: 100%;
                    height: auto;
                    padding: 1rem 1.4rem 0.5rem;
                    background: transparent;
                    border-radius: 24px;
                    border: 2px solid #6B6B83;
                    transition: all 0.2s ease-in-out;
                    &::placeholder {
                        opacity: 0;
                        visibility: hidden;
                        color: transparent;
                    }
                    &:focus {
                        outline: none;
                        border-color: #FFF;
                    }
                    &:focus ~ .mktoLabel {
                        top: 3px;
                        left: 1rem;
                        z-index: 5;
                        font-size: 12px;
                        color: #6B6B83;
                        font-family: "Scandia Medium", sans-serif;
                        transition: all 0.2s ease-in-out;
                    }
                }
                .mktoLabel {
                    position: absolute;
                    font-family: "Scandia Medium", sans-serif;
                    font-size: 16px;
                    line-height: inherit;
                    left: 1rem;
                    top: 0.75rem;
                    padding: 0 0.5rem;
                    transition: all 0.3s ease;
                    color: #FFF;
                }
            }
            
        }
    }
    .mktoButton {
        display: inline!important;
        padding: 12px 30px!important;
        border-radius: 100px!important;
        font-weight: bold!important;
        font-size: var(--fontSize-1)!important;
        min-width: 175px!important;
        display: inline-block!important;
        text-align: center!important;
        background: #FFF!important;
        color: #000!important;
        font-family: var(--font-body)!important;
        border: none!important;
        transition: all .2s!important;
        width: 100%!important;
        &:hover {
            color: white!important;
            text-decoration: none!important;
            background: #6B6B83!important;
        }
        &:active{
            background: #6B6B83!important;
        }
    }
    .mktoAsterix{
        display:none!important;
    }


    .mktoRadioList {
        padding-top: 40px;
        padding-bottom: 20px;
        padding-left: 5px;
        max-width: 200px;
        display: flex;
        align-items: center;
        flex-direction: row;
    }
    .mktoRadioList input[type="radio"] {
        top: inherit !important;
        left: inherit !important;
        width: inherit !important;
        position: inherit !important;
        float: right;
        clear: right;
        width: 20px !important;
        height: 20px !important;
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }
    .mktoRadioList label {
        display: block !important;
        float: left;
        clear: left;
        margin: 0 !important;
        padding: 0 !important;
        margin-right: 20px!important;
        color: #FFF;
        padding-left: 4px!important;
    }
    #Lblheadcountbelow100 {
        top: 0 !important;
        left: 0 !important;
    }
    .mktoButtonRow {
        margin-top: 20px;
    }
`;
