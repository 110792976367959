import { MainContainer, TextComponent, Title, Subtitle, ImageComponent } from "./Header.styles"

import React from 'react';

export const Header = (props) => {

  return(
    <MainContainer>
      <TextComponent>
        <Title>Flexibility like never before</Title>
        <Subtitle>Grubhub Pay Card Coming Soon - The new way to extend your meal perks program to everywhere food is sold.</Subtitle>
      </TextComponent>
    </MainContainer>
  )
};
